import React, {useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Technology.css";
import saffron1 from "../../assets/technology-1.jpg";
import saffron2 from "../../assets/technology-2.jpg";

const Technology = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);
  return (
    <section id="technology">
      <div className="tech-container" >
        {/* Header Section */}
        <div className="vision-header" data-aos="fade-up">
          <h1>Technology in Agriculture</h1>
        </div>

        {/* Content Section */}
        <div className="tech-content" data-aos="fade-up">
          {/* Left Section: Text */}
          <div className="tech-text">
            <p>
              Saffron AeroFarming uses aeroponics technology to grow 100% pure Kashmiri saffron sustainably indoors. By
              eliminating soil and using minimal water, we provide a pesticide-free, organic farming solution that’s
              efficient, eco-friendly, and highly productive.
            </p>
            <p>
              Our mission is to empower individuals to cultivate premium saffron in any environment with cutting-edge
              technology and expert guidance.
            </p>
            {/* <a href="https://www.goldenthreads.com" className="tech-link">
              www.goldenthreads.com
            </a> */}
          </div>

          {/* Right Section: Images */}
          <div className="tech-images">
            <div className="circle-image">
              <img src={saffron1} alt="Saffron flower" />
            </div>
            <div className="circle-image">
              <img src={saffron2} alt="Saffron farming" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technology;
