import React, { useEffect } from "react";
import "./Header.css";
import AOS from "aos";
import logo from '../../assets/header-logo.png';
import backgroundImage from '../../assets/home-page-background.jpg'

import "aos/dist/aos.css";

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <section
    id="header"
    style={{
      backgroundImage: `url(${backgroundImage})`, // Add your background image
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
  >
      <div className="header" data-aos="fade-up">
      <img 
          src={logo}
          alt="Golden Threads Logo" 
          className="header-logo"
        />
        <p>
          Revolutionising saffron cultivation with aeroponics to deliver
          authentic Kashmiri saffron and preserve its rich heritage.
        </p>
        <a href="#about-us" className="cta">
          Learn More
        </a>
      </div>
    </section>
  );
};

export default Header;
