import styled from 'styled-components';

const Footer = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // alert(`Copied: ${text}`);
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };  
  return (
  <FooterContainer>
    <FooterContent>
      <p>&copy; 2024 Golden Threads. All rights reserved.</p>
      <ContactInfo>
      <p>
            Email:{" "}
            <CopyableText onClick={() => copyToClipboard("goldenthreads1996@gmail.com")}>
              goldenthreads1996@gmail.com
            </CopyableText>
          </p>
          <p>
            Phone:{" "}
            <CopyableText onClick={() => copyToClipboard("+91 7499512750")}>
              +91 7499512750
            </CopyableText>
          </p>
      </ContactInfo>
    </FooterContent>
  </FooterContainer>
  )
};

const FooterContainer = styled.footer`
  background-color: #4CAF50;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  margin-top: auto;
`;

const FooterContent = styled.div`
  p {
    margin: 0.5rem 0;
  }

  a {
    color: #FFC107;
    text-decoration: none;
  }
`;

const ContactInfo = styled.div`
  margin-top: 0.5rem;

  p {
    margin: 0.25rem 0;
  }
`;

// Styled component for copyable text
const CopyableText = styled.span`
  // color: #FFC107;
  cursor: pointer;
  // text-decoration: underline;

  &:hover {
    color: #FFD54F;
  }
`;
export default Footer;
