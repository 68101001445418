import React, { useEffect } from "react";
import "./Vision.css"; // Custom CSS file
import AOS from "aos";
import "aos/dist/aos.css";

const Vision = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <section id="vision">
      <div className="vision-container">
        <header className="vision-header" data-aos="fade-down">
          <h1>Our Vision</h1>
        </header>

        <div className="vision-cards">
          {/* Card 1 */}
          <div className="vision-card" data-aos="fade-up">
            <h2>We Offer</h2>
            <p>
              We provide free training to help you grow 100% pure saffron using
              original Kashmiri saffron bulbs and aeroponics technology—a
              sustainable, pesticide-free, and water-efficient farming method.
              Along with expert guidance, we offer essential equipment and
              ongoing support to ensure your success in creating a thriving,
              organic indoor saffron farm.
            </p>
          </div>

          {/* Card 2 */}
          <div className="vision-card" data-aos="fade-up" data-aos-delay="200">
            <h2>We Commit</h2>
            <p>
              We are committed to providing you with high-quality saffron bulbs,
              expert training, and personalized support to help you successfully
              set up and run your indoor saffron farm. We promise to guide you
              through sustainable, pesticide-free farming practices using
              aeroponics technology, ensuring you have the tools and knowledge
              needed to thrive.
            </p>
          </div>

          {/* Card 3 */}
          <div className="vision-card" data-aos="fade-up" data-aos-delay="400">
            <h2>We Support</h2>
            <p>
              We offer online training sessions that cover every aspect of
              growing saffron using aeroponics technology. The training includes
              in-depth modules on saffron bulb handling, aeroponics setup, and
              organic farming practices. After completing the session, our
              expert team will provide personalized support to guide you through
              the setup of your indoor farm, ensuring you are fully equipped to
              grow saffron successfully and sustainably.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
