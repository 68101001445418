import React, { useEffect } from "react";
import "./Feature.css"; // Create this CSS file for custom styling
import AOS from "aos";
import "aos/dist/aos.css";

import imageTop from "../../assets/about-us-1.jpg"; // Replace with the blue LED farming image
import imageBottom from "../../assets/about-us-2.jpg"; // Replace with the workers harvesting saffron image

const Features = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <section id="about-us">
      <div className="container about-us">
      <header className="vision-header" data-aos="fade-down">
          <h1>About Us</h1>
        </header>

        <div className="about-content">
          <div className="about-images" data-aos="fade-right">
            <img src={imageTop} alt="Indoor Saffron Farming" className="about-img" />
            <img src={imageBottom} alt="Saffron Harvesting" className="about-img" />
          </div>
          <div className="about-text" data-aos="fade-left">
            <h3>Pure, Sustainable Saffron Farming</h3>
            <p>
              At Golden Threads, we specialize in growing 100% pure saffron using
              innovative aeroponics technology, ensuring sustainable, pesticide-free
              cultivation that produces premium-quality crops.
            </p>
            <h3>Empowering Future Farmers</h3>
            <p>
              We provide expert training and support to help individuals achieve their
              indoor farming dreams, offering the tools and knowledge needed to grow
              saffron organically and efficiently, regardless of location.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
